import React from 'react';
import { withStyles } from '@material-ui/core';
import { Form } from 'react-final-form'
import _ from 'lodash';

import { FormField } from 'components';

import styles from './styles';
import fields from './fields';

const DocumentForm = props => {
  const {
    classes,
    document,
    onSubmit,
  } = props;

  const validate = (values) => {
    let errors = {};

    if (!values.title) {
      errors.title = 'Required';
    }
 
    //Validation doesn't work ...
    // _.forEach(_.filter(fields, { required: true }), (field) => {
    //   if (!values[field]) {
    //     errors[field] = 'Required';
    //   }
    // });

    return errors;
  }

  return (
    <Form
      initialValues={document}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form
          id="documentForm"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          
          {_.map(fields, field => <FormField key={field.name} {...field}/>)}
          
        </form>
      )}
    />
  );
};

export default withStyles(styles)(DocumentForm);
