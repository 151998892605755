import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import {
  makeStyles,
} from '@material-ui/core';
import {
  CommonDialog,
  CommonPanel,
} from 'components';
import NormSearch from 'views/NormsCalc/components/NormCalcTreeTable';
import api from 'utils/api';
import { toast } from 'utils/helpers';

const useStyles = makeStyles({
  root: {},
  footerWrap: {
    marginTop: 40,
  },
  viewWrap: {
    // height: '600px',
    // overflow: 'auto',
  }
})

const RatesModal = ({ objectId, itemId, onClose, version, priceSettings, initialSearchString, quantityCalc, itemActions, activeItem, setActiveItem }) => {
  const history = useHistory();
  const classes = useStyles();
  // const { projectId, objectId, itemId } = useParams();
  const [mapping, setMapping] = useState([]);

  const fetchMapping = async () => {
    try {
      const { data } = await api.get(`/estimates/${objectId}/items/${itemId}/mapping`);

      setMapping(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchMapping();
  }, []);

  const handleClose = (hadChanges = false) => {
    // history.push(`/projects/${projectId}/objects/${objectId}?closed=true`);
    onClose(hadChanges);
  };

  const handleSelectedRates = async (rates) => {
    try {
      let addedRateId = null;
      if (activeItem.createRateImportItem) {
        addedRateId = await itemActions.addItem(activeItem, 'rate');
        setActiveItem({ _id: addedRateId, parent: activeItem._id });
      }

      await api.post(`/estimates/${objectId}/items/${addedRateId || itemId}/rates`, { rates }).catch(error => {
        if (error.response.data.error == 'RATE_ITEM_COUNT_OVER_TRIAL_LIMIT') {
          toast('Viršytas sąmatos bandomosios paslaugos įkainių kiekis', 'error');
        }
      });
    } catch (err) {
      console.error(err);
    }
    handleClose(true);
  }



  return (
    <CommonDialog
      visible
      onClose={handleClose}
      maxWidth="lg"
      style={{ position: 'relative' }}
      title="Įkainiai"
    >
      <div className={classes.viewWrap}>
        {!!mapping.length && (
          <CommonPanel title="Papildoma informacija" collapsible noPadding>
            <div style={{ padding: '5px 10px' }}>
              {mapping.map((m) => (
                <div style={{ width: '20%', display: 'inline-block', marginBottom: 5, verticalAlign: 'top' }}>
                  <small>
                    <strong>{m.key} {m.title}:</strong><br />{m.value}
                  </small>
                </div>
              ))}
            </div>
          </CommonPanel>
        )}

        <Search
          onCancel={handleClose}
          version={version}
          onSelected={(rates) => handleSelectedRates(rates)}
          priceSettings={priceSettings}
          initialSearchString={!activeItem.createRateImportItem ? initialSearchString : ''}
          quantityCalc={quantityCalc}
        />
      </div>

    </CommonDialog>
  )
}

const Search = ({ onCancel, onSelected, version, priceSettings, initialSearchString, quantityCalc }) => {

  return (
    <NormSearch
      isEmbeded
      onSelectionChange={onSelected}
      onCancel={onCancel}
      lockedVersionCode={version}
      overridePriceSettings={priceSettings}
      initialSearchString={initialSearchString}
      quantityCalc={quantityCalc}
    />
  )
}

export default RatesModal;