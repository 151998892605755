import _ from 'lodash';

import { toast } from 'views/App/_actions';
import * as dataService from '../../shared/_services';
import * as projectService from 'views/Projects/shared/_services';
import { blobToFileDownload } from 'utils/helpers';
import {
  SELECT_PROJECT_SUCCESS,
  SELECT_PROJECT_FAILURE,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  GET_TYPES_SUCCESS,
  GET_TYPES_FAILURE,
  GET_VERSIONS_FAILURE,
  GET_VERSIONS_SUCCESS,
  GET_HIERARCHY_FAILURE,
  GET_HIERARCHY_SUCCESS,
  GET_MATERIALS_FAILURE,
  GET_MATERIALS_SUCCESS,
  SELECT_MATERIALS_SUCCESS,
  SELECT_MATERIALS_FAILURE,
  GET_COMBINATIONS_SUCCESS,
  GET_COMBINATIONS_FAILURE,
  GET_CALCULATIONS_SUCCESS,
  GET_CALCULATIONS_FAILURE,
  UPDATE_CALCULATIONS_SUCCESS,
  UPDATE_CALCULATIONS_FAILURE,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  SENDING_REQUEST,
  VALUE_CHANGED,
  RESET_FORM,
  RESET_USER_INPUT,
  TOGGLE_MATERIALS,
  SKIP_MATERIALS,
  TOGGLE_RESULTS,
  PROJECT_CLEARED,
  GET_RECORD_SUCCESS,
  GET_RECORD_FAILURE,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  START_LOADING_RECORD,
  FINISH_LOADING_RECORD,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
} from './_constants';

const WAIT_INTERVAL = 1000;
let recalcTimer = null;

export const resetForm = () => (dispatch) => {
  dispatch({ type: RESET_FORM });
}

export const saveForm = () => async (dispatch, getState) => {
  const state = getState().recoveryPriceForm;
  const { project } = state.record;

  if (!project) {
    toast('Neišsaugota, nes neparinktas projektas', 'error');
    return;
  }

  dispatch({ type: SENDING_REQUEST });

  try {
    const record = await dataService.createOne(state.record);
    // created successfully
    dispatch({ type: CREATE_SUCCESS, record });
    toast('Išsaugota', 'success');
  } catch (error) {
    // create failed
    dispatch({ type: CREATE_FAILURE });
    let msg = 'Nepavyko išsaugoti';
    try {
      msg = error.response.data.message;
    } catch (err) {
      //...
    }
    toast(msg, 'error');
  }
}

export const updateForm = () => async (dispatch, getState) => {
  const state = getState().recoveryPriceForm;
  const { project } = state.record;

  if (!project) {
    toast('Neišsaugota, nes neparinktas projektas', 'error');
    return;
  }

  dispatch({ type: SENDING_REQUEST });

  try {
    await dataService.updateOne(state.record);

    // created successfully
    dispatch({ type: UPDATE_SUCCESS });
    toast('Išsaugota', 'success');
  } catch (error) {
    // create failed
    dispatch({ type: UPDATE_FAILURE });
    let msg = 'Nepavyko išsaugoti';
    toast(msg, 'error');
  }
}

export const valueChanged = (data, changeValueOnly = false) => (dispatch, getState) => {
  const state = getState().recoveryPriceForm;

  if (!state.isLoadingRecord && state.record.amount) {
    if (data.name == 'pricing' || data.name == 'type' || data.name == 'buildingType') {
      if (!window.confirm('Ar tikrai norite pakeisti reikšmę? Neišsaugoti skaičiavimo pakeitimai bus prarasti')) {
        return;
      }
    }
  }

  dispatch({ type: VALUE_CHANGED, data });

  if (changeValueOnly) {
    return;
  }

  switch (data.name) {
    case 'project':
      if (data.value) {
        dispatch(getOneProject(data.value));
      } else {
        dispatch({ type: PROJECT_CLEARED });
      }
      break;

    case 'type':
      dispatch(resetUserInput());
      // Load versions by type
      if (!state.isLoadingRecord) {
        dispatch(getVersions({ type: data.value }));
      }
      break;

    case 'buildingType':
      dispatch(resetUserInput());

      const unit = data.value ? data.value.unit : null;

      dispatch(valueChanged({ name: 'unit', value: unit }));
      setMaterialsByBuildingType(data, dispatch, state);
      break;

    case 'pricing':
      if (!state.isLoadingRecord) {
        dispatch(resetUserInput());
        dispatch({ type: VALUE_CHANGED, data: { name: 'buildingType', value: '' } });
        dispatch(getHierarchy());
      }
      break;

    case 'materials':
      dispatch({ type: VALUE_CHANGED, data: { name: 'wallType', value: '' } });
      dispatch(getAvailableMaterials(data.value));
      break;

    case 'wallType':
      if (data.value == state.record.wallType) {
        dispatch({ type: VALUE_CHANGED, data: { name: 'usageStartDateChanged', value: true } });
        dispatch(recalculate());
      } else {
        if (!state.isLoadingRecord) {
          dispatch({ type: VALUE_CHANGED, data: { name: 'usageStartDateChanged', value: true } });//data: { name: 'wallType', value: data.value } });
          dispatch(reloadCalulations());
        }
      }
      break;

    case 'usageStartDate':
      // Set flag to force clearing modified depreciation percent values
      if (!state.isLoadingRecord) {
        dispatch({ type: VALUE_CHANGED, data: { name: 'usageStartDateChanged', value: true } });
        dispatch(recalculate());
      }
      break;

    case 'coefficients':
    case 'corrections':
    case 'percents':
      if (!state.isLoadingRecord) {
        dispatch(recalculate());
      }
      break;

    case 'amount':
      if (!state.isLoadingRecord && state.data.materials.length === 0) {
        const params = {
          code: state.record.buildingType.code,
          version: state.record.pricing.code,
          type: state.record.type,
        }

        dispatch(getCombinations(params));
        break;
      }

      if (!state.isLoadingRecord) {
        dispatch(recalculate());
      }
      break;


    default:
      break;
  }

  dispatch(toggleMaterials());
}

const reloadCalulations = (update) => (dispatch, getState) => {
  // Trigger calculations
  const { record } = getState().recoveryPriceForm;
  dispatch(getCalculations({ record }, update));
}

const toggleMaterials = () => (dispatch, getState) => {
  const {
    record,
    data,
  } = getState().recoveryPriceForm;

  const show = record.type
    && record.buildingType
    && record.pricing
    && record.unit
    && record.amount
    && data.initialMaterials.length;

  dispatch({ type: TOGGLE_MATERIALS, show });
}

export const getRecord = (id) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });
  dispatch({ type: START_LOADING_RECORD });

  try {
    const loadedRecord = await dataService.getOne(id);
    // API returns timestamp, converting to years only
    // loadedRecord.usageStartDate = new Date(loadedRecord.usageStartDate).getFullYear();

    dispatch({ type: GET_RECORD_SUCCESS, loadedRecord });

    // Load variables
    dispatch(attemptToLoadRecordValues('_id', '_id'));
    dispatch(attemptToLoadRecordValues('amount', 'amount'));
    dispatch(attemptToLoadRecordValues('unit', 'unit'));
    dispatch(attemptToLoadRecordValues('title', 'title'));
    dispatch(attemptToLoadRecordValues('description', 'description'));

    // Load references
    dispatch(getProjects(loadedRecord.project));
    dispatch(getTypes());

  } catch (error) {
    dispatch({ type: GET_RECORD_FAILURE, error });
  }
}

export const getProjects = (id) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const { items: projects } = await projectService.getAll();

    dispatch({ type: GET_PROJECTS_SUCCESS, projects });

    // If laoding existing project
    if (id) {
      dispatch(getOneProject(id));
    }
  } catch (error) {
    dispatch({ type: GET_PROJECTS_FAILURE, error });
  }
}

export const getOneProject = (id) => async (dispatch) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const project = await projectService.getOne(id);

    dispatch({ type: SELECT_PROJECT_SUCCESS, project });
  } catch (error) {
    dispatch({ type: SELECT_PROJECT_FAILURE, error });
  }
}

export const getTypes = () => async (dispatch, getState) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const types = await dataService.getTypes();

    dispatch({ type: GET_TYPES_SUCCESS, types });

    const state = getState().recoveryPriceForm;
    if (state.isLoadingRecord) {
      dispatch(attemptToLoadRecordValues('type', 'type'));
      dispatch(getVersions({ type: state.loadedRecord.type }));
    }
  } catch (error) {
    dispatch({ type: GET_TYPES_FAILURE, error });
  }

  dispatch(toggleMaterials());
}

export const getVersions = (params) => async (dispatch, getState) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const versions = await dataService.getVersions(params);

    dispatch({ type: GET_VERSIONS_SUCCESS, versions });

    const state = getState().recoveryPriceForm;
    if (!state.record.pricing) {
      dispatch(valueChanged({ name: 'pricing', value: versions.find(version => version.code != null) }));
    }

    if (state.record.pricing && !state.isLoadingRecord) {
      dispatch({ type: VALUE_CHANGED, data: { name: 'buildingType', value: '' } });
      dispatch(getHierarchy());
    }

    dispatch(attemptToLoadRecordValues('pricing', 'pricing'));
    if (state.isLoadingRecord) {
      dispatch(getHierarchy());
    }
  } catch (error) {
    dispatch({ type: GET_VERSIONS_FAILURE, error });
  }

  dispatch(toggleMaterials());
}

export const getHierarchy = () => async (dispatch, getState) => {
  dispatch({ type: SENDING_REQUEST });

  const state = getState().recoveryPriceForm;
  const params = {
    type: state.record.type,
    version: state.record.pricing.code,
  };

  try {
    const hierarchy = await dataService.getHierarchy(params);

    dispatch({ type: GET_HIERARCHY_SUCCESS, hierarchy });

    dispatch(attemptToLoadRecordValues('buildingType', 'buildingType'));
  } catch (error) {
    dispatch({ type: GET_HIERARCHY_FAILURE, error });
  }

  dispatch(toggleMaterials());
}

export const getMaterials = (params) => async (dispatch, getState) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const materials = await dataService.getMaterials(params);

    dispatch({ type: GET_MATERIALS_SUCCESS, materials });

    const { record } = getState().recoveryPriceForm;
    const { type, buildingType, pricing } = record;
    
    if (type && type.toLowerCase() == 'ntk inzineriniai') {
      const params = { 
        code: buildingType.code,
        version: pricing.code,
        type,
      };
      dispatch(getCombinations(params));
    }
    dispatch(attemptToLoadRecordValues('materials', 'materials'));
  } catch (error) {
    dispatch({ type: GET_MATERIALS_FAILURE, error });
  }

  dispatch(toggleMaterials());
}

const getAvailableMaterials = (selectedMaterials) => async (dispatch, getState) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const state = getState().recoveryPriceForm;
    let params = {
      code: state.record.buildingType.code,
      version: state.record.pricing.code,
      materials: selectedMaterials,
    };

    const materials = await dataService.getMaterials(params);

    dispatch({ type: SELECT_MATERIALS_SUCCESS, materials });

    if (selectedMaterials.length == 0) {
      dispatch({ type: VALUE_CHANGED, data: { name: 'selectedMaterials', value: null } });

      const { type, buildingType, pricing } = state.record;
      if (type && type.toLowerCase() == 'ntk inzineriniai') {
        const params = { 
          code: buildingType.code,
          version: pricing.code,
          type,
        };
        dispatch(getCombinations(params));
      }
    } else {
      params.type = state.type;
      dispatch(getCombinations(params));
    }
    dispatch(getCombinations(params));
  } catch (error) {
    dispatch({ type: SELECT_MATERIALS_FAILURE, materials: [] });
  }

  dispatch(toggleMaterials());
}

const getCombinations = (params, recalc = false) => async (dispatch, getState) => {
  dispatch({ type: SENDING_REQUEST });
  const state = getState().recoveryPriceForm;

  try {
    let p;
    if (state.data.materials.length === 0) {
      p = {
        code: state.record.buildingType.code,
        version: state.record.pricing.code,
        type: state.record.type,
      }
    } else {
      p = params;
    }

    const combinations = await dataService.getCombinations(p);

    dispatch({ type: GET_COMBINATIONS_SUCCESS, combinations });

    if (params.type && params.type.toLowerCase() == 'ntk inzineriniai') {
      // Engineering structures
      const joinedCombi = _.uniq(_.map(combinations, combi => combi.code)).join(';');
      dispatch({ type: VALUE_CHANGED, data: {
        name: 'combinationCode',
        value: joinedCombi,
      }});
    } 
    
    // TODO hack, discuss this place with team
    if (combinations.length === 1) {
      dispatch({ type: SKIP_MATERIALS, show: true });

      // Pick first combination code
      const { code } = _.first(combinations);
      dispatch({
        type: VALUE_CHANGED, data: {
          name: 'combinationCode',
          value: code,
        }
      });

      dispatch(recalc ? recalculate() : reloadCalulations());
    }
  } catch (error) {
    dispatch({ type: GET_COMBINATIONS_FAILURE, error });
  }
}

const getCalculations = (params, update) => async (dispatch, getState) => {
  dispatch({ type: SENDING_REQUEST });
  try {
    const record = await dataService.getCalculations(params);

    dispatch({ type: GET_CALCULATIONS_SUCCESS, record });
    dispatch({ type: TOGGLE_RESULTS, show: true });

    const state = getState().recoveryPriceForm;
    if (state.isLoadingRecord) {
      dispatch(attemptToLoadRecordValues('corrections', 'corrections'));
      dispatch(attemptToLoadRecordValues('coefficients', 'coefficients'));
      // dispatch(attemptToLoadRecordValues('totals', 'totals'));
      dispatch(attemptToLoadRecordValues('wallType', 'wallType'));
      dispatch(attemptToLoadRecordValues('usageStartDate', 'usageStartDate'));
      dispatch(attemptToLoadRecordValues('percents', 'percents'));
      // dispatch({ type: VALUE_CHANGED, data: { name: 'usageStartDateChanged', value: true } });
      dispatch(reloadCalulations(true));
      // dispatch(updateCalculations());

      dispatch({ type: FINISH_LOADING_RECORD });
    }

    if (update) {
      dispatch(updateCalculations());
    }
  } catch (error) {
    dispatch({ type: GET_CALCULATIONS_FAILURE, error });
  }
}

export const getReport = (params) => async (dispatch, getState) => {
  dispatch({ type: SENDING_REQUEST });

  try {
    const data = await dataService.getRecoveryPriceReport(params);
    const { fileName, contentType, content } = data;

    var bytes = new Uint8Array(content);
    const blob = new Blob([bytes], { type: contentType });

    blobToFileDownload(fileName, blob);
    dispatch({ type: GET_REPORT_SUCCESS, data });
  } catch (error) {
    dispatch({ type: GET_REPORT_FAILURE, error });
  }
}

export const updateCalculations = () => async (dispatch, getState) => {
  dispatch({ type: SENDING_REQUEST });
  try {
    const { record } = getState().recoveryPriceForm;
    const updatedRecord = await dataService.updateCalculations({ record });

    dispatch({ type: UPDATE_CALCULATIONS_SUCCESS, record: updatedRecord });
    dispatch({ type: TOGGLE_RESULTS, show: true });
  } catch (error) {
    dispatch({ type: UPDATE_CALCULATIONS_FAILURE, error });
  }
}

const recalculate = () => (dispatch) => {
  clearTimeout(recalcTimer);
  recalcTimer = setTimeout(() => dispatch(updateCalculations()), WAIT_INTERVAL);
}

/**
 * HELPERS
 */

const setMaterialsByBuildingType = ({ value }, dispatch, state) => {
  const params = {
    code: value ? value.code : null,
    version: state.record.pricing.code,
  };

  if (value) {
    dispatch(getMaterials(params));
  } else {
    dispatch({ type: GET_MATERIALS_SUCCESS, materials: [] });
  }
}

const attemptToLoadRecordValues = (name, value) => (dispatch, getState) => {
  const { isLoadingRecord, loadedRecord } = getState().recoveryPriceForm;
  if (isLoadingRecord && loadedRecord) {
    dispatch(valueChanged({ name, value: loadedRecord[value] }));
  }
}

const resetUserInput = () => (dispatch, getState) => {
  const { isLoadingRecord } = getState().recoveryPriceForm;

  if (!isLoadingRecord) {
    dispatch({ type: RESET_USER_INPUT });
  }
}