import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  withStyles,
  Typography,
  Grid,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Description as DescriptionIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { ENABLE_NORMS_EXCEL_EXPORT } from 'utils/config';
import NormsCalc from 'views/NormsCalc';
import {
  CommonPanel,
  NormSearch,
  NormVersionPicker,
  FormField2,
  CommonDialog,
  TrialHandler,
  NormPriceComposition,
  NormCoefficients,
  NormPriceSettings,
  ResourceSearch,
  TabPanel,
} from 'components';

import { EDU_MODE } from 'utils/config';
import { history } from 'utils/history';
import { twoOrMoreDecimals, getQueryVariable, getCurrentAccount, blobToFileDownload, toast } from 'utils/helpers';
import api from 'utils/api';
import * as projectService from 'services/projects';
import styles from './styles';

const SERVICE_CODE = 'normsCalc';

class NormCalcTreeTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paddingTop: 86,
      expanded: [],
      showExportDialog: false,
      showTransferToEstimateDialog: false,
      showPriceSettingsDialog: false,
      showResourceSearch: false,
      recalcWithPriceSettings: false,
      showRateImport: false,
      rateImportSelected: [],
      isTrial: false,
      showTrialMessage: false,
      exportAnchorEl: null,
      transferToEstimateAnchorEl: null,
      tempPriceSettings: [],
      exportStep: 1,
      transferToEstimateStep: 1,
      exportPriceSettings: [],
      transferToEstimatePriceSettings: [],
      newEstimateTitle: null,
      hasEstimatesAccess: null,
      newProjectTitle: null,
      newProjectCode: null,
      selected: [],
      tabValue: [],
    };

    this.elRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    if (getQueryVariable('trial')) {
      this.setState({ isTrial: true });
      return;
    }

    if (this.props.overridePriceSettings) {
      this.props.store.init(this.props.overridePriceSettings);
    } else {
      this.props.store.init();
    }

    if (this.props.quantityCalc) {
      this.props.store.applyQuantityCalc(this.props.quantityCalc);
    }

    if (this.props.lockedVersionCode) {
      this.props.store.setVersion(this.props.lockedVersionCode);
    }

    if (_.includes(getCurrentAccount().trialServices, SERVICE_CODE)) {
      this.setState({ showTrialMessage: true });
    }

    if (_.includes(getCurrentAccount().roles, 'estimates.layout')) {
      this.setState({ hasEstimatesAccess: true });
    } else {
      this.setState({ hasEstimatesAccess: false });
    }

    window.addEventListener('scroll', this.handleScroll, false);

    // this.loadData();
  }

  async loadData() {
    await this.props.store.loadData();

    // Set padding for mobile view
    try {
      const paddingTop = window.document.querySelector('.sticky-table-header').offsetHeight;
      this.setState({ paddingTop });
    } catch (err) {
      console.log(err)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
    
    this.props.store.reset();
  }

  handleScroll = _.debounce(() => {
    const node = this.elRef.current;

    if (node !== undefined) {
      const header = node.querySelector('.sticky-table-header');
      const fromViewTop = node.getBoundingClientRect().top;
      const topbarHeight = document.querySelector('.top-navbar').offsetHeight;

      node.style.paddingTop = `${header.offsetHeight}px`;

      if (fromViewTop < 0) {
        header.style.top = Math.abs(fromViewTop - topbarHeight) + 'px';
      } else {
        header.style.top = '0px';
      }
    }
  })

  onTreeRowClick(e, item) {
    if (e.target.tagName === 'INPUT') return;

    this.props.store.handleExpand(item);
  }

  onParameterRowClick(e, item) {
    if (e.target.tagName === 'INPUT') return;

    this.props.store.handleParameterExpand(item);
  }

  onSearchResultSelected({ codePath }) {
    
    this.props.store.expandPath(codePath.split(';'), this.props.isEmbeded);
  }

  onInputChange(name, value, item, triggerRecalc = true, instantRecalc = false) {
    _.set(item, name, value);

    if (triggerRecalc) this.props.store.doRecalc(item);

    if (instantRecalc) this.props.store.recalculate(item);
  }

  onInputKeyDown(e, item) {
    if (e.keyCode === 13) {
      // Cancel debouncer initiated by onInputChange
      this.props.store.doRecalc.cancel();

      // Recalculate
      this.props.store.recalculate(item);
    }
  }

  onAddCoefficientClick(item) {
    if (!item.details.customCoefficients) {
      item.details.customCoefficients = [];
    }

    const existsEmpty = _.find(item.details.customCoefficients, i => !i.title) !== undefined;
    if (existsEmpty) return;

    item.details.customCoefficients.push({
      code: `${item.details.customCoefficients.length}-${_.random(9999)}`,
      title: null,
      selected: false,
      k1: 1,
      k2: 1,
      k3: 1,
    });
  }

  renderTotalsUnit(value) {
    const { t } = this.props;
    switch (value) {
      case 'percent':
        return '%'
      
      case 'coef':
        return t('koef.');

      case 'amount':
        return t('suma');

      default:
        return '-';
    }
  } 

  renderExpandArrow(code, value) {
    const { tabValue } = this.state;

    if (tabValue[code] == value) {
      return <ArrowDropUpIcon style={{ marginTop: 4 }}/>;
    } else {
      return <ArrowDropDownIcon style={{ marginTop: 4 }}/>;
    }
  }

  // renderDetailsRow({ details, level }) {
  renderDetailsRow(item) {
    const { code, details } = item;
    const { classes, t } = this.props;
    const { tabValue } = this.state;

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    const handleTabChange = (event, newValue, code) => {
      const tabs = this.state.tabValue;
      if (this.state.tabValue[code] == newValue) {
        tabs[code] = null;
      } else {
        tabs[code] = newValue;
      }
      this.setState({ tabValue: tabs });
    };
    
    const contents = (
      <React.Fragment>
        <div style={{ marginBottom: 10 }}>        
          <CommonPanel style={{ padding: 5 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {!details.editTitle ? (
                  <>
                    <Typography variant="h6" style={{ textAlign: 'left' }}>
                      {details.titleCalc || '-'}
                      <Link title={t('Tikslinti darbo pavadinimą')} onClick={() => details.editTitle = true} style={{ marginLeft: 5 }}>
                        <EditIcon fontSize="small" style={{ verticalAlign: 'top' }} />
                      </Link>
                    </Typography>
                  </>
                ) : (
                  <FormField2
                    type="text"
                    name={'details.titleCalc'}
                    value={details.titleCalc}
                    onChange={e => {
                      const needsRecalc = e.value === '';
                      this.onInputChange(e.name, e.value, item, needsRecalc);
                    }}
                    onKeyDown={e => this.onInputKeyDown(e, item)}
                    onBlur={() => details.editTitle = false}
                    placeholder={`${t('Darbo pavadinimas')}...`}
                    rows={3}
                    multiline
                    naked
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <table className={classes.table}>
                  <thead>
                    <tr className="no-hover">
                      <th style={{ width: '33%' }}>{t('Kiekis')}</th>
                      <th style={{ width: '33%' }}>{t('Kaina')}</th>
                      <th>{t('Vertė')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="no-hover">
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="number"
                          name="quantityCalc"
                          value={item.quantityCalc || 0}
                          onChange={(e) => this.onInputChange(e.name, e.value, item)}
                          onKeyDown={e => {
                            this.onInputKeyDown(e, item);
                          }}
                          min={0}
                          step={0.0001}
                          naked
                        />
                      </td>
                      <td className={classes.textRight} style={{ padding: '2px 8px' }}>
                        {twoOrMoreDecimals(item.totals ? item.totals.unitAmountPriceVat : 0)}
                      </td>
                      <td className={classes.textRight} style={{ padding: '2px 8px' }}>
                        {twoOrMoreDecimals(item.totals ? item.totals.amountPriceVat : 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div style={{ textAlign: 'right', marginTop: 5, marginRight: 5 }}>
                  <Typography variant="body1">
                    <Link onClick={() => this.props.store.resetItem(item)}>
                      {t('Atstatyti pirmines reikšmes')}
                    </Link>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CommonPanel>
        </div>

        <div style={{ marginBottom: 10 }}>
          <CommonPanel
            title="Resursai"
            collapsible
            collapsed={details.resources.length === 0}
            disabled={details.resources.length === 0}
            style={{ padding: 5, overflow: 'auto' }}
          >
            {details.resources.length ? (
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th style={{ width: '15%' }}>{t('Kodas')}</th>
                    <th style={{ width: '35%' }}>{t('Pavadinimas')}</th>
                    <th style={{ width: '10%' }}>{t('Matas')}</th>
                    <th style={{ width: '10%' }}>{t('Norma')}</th>
                    <th style={{ width: '10%' }}>{t('Kiekis')}</th>
                    <th style={{ width: '10%' }}>{t('Kaina')}</th>
                    <th style={{ width: '10%' }}>{t('Suma')}</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(details.resources, (resource, resourceIdx) => (
                    <tr key={`${resource.code}-resource`}>
                      <td className={classes.textCenter}>{resource.code}</td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="text"
                          name={`details.resources[${resourceIdx}].titleCalc`}
                          value={resource.titleCalc}
                          onChange={(e) => {
                            // Apply same value to parameter resources with same code
                            for (const param of details.parameters) {
                              if (!param.resources) continue;
                              for (const paramResource of param.resources) {
                                if (paramResource.code !== resource.code) continue;
                                paramResource.titleCalc = e.value;
                              }
                            }

                            const needsRecalc = e.value === '';
                            this.onInputChange(e.name, e.value, item, needsRecalc);
                          }}
                          onKeyDown={e => this.onInputKeyDown(e, item)}
                          placeholder={`${t('Resurso pavadinimas')}...`}
                          naked
                        />
                      </td>
                      <td>{resource.unit}</td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="number"
                          name={`details.resources[${resourceIdx}].normCalc`}
                          value={resource.normCalc}
                          className={resource.normCalc === 0 && classes.inputError}
                          onChange={(e) => {
                            this.onInputChange(`details.resources[${resourceIdx}].normModifier`, null, item, false);

                            for (const param of details.parameters) {
                              if (!param.resources) continue;
                              for (const paramResource of param.resources) {
                                if (paramResource.code !== resource.code) continue;
                                paramResource.normCalc = 0;
                              }
                            }

                            this.onInputChange(e.name, e.value, item);
                          }}
                          onKeyDown={e => {
                            this.onInputKeyDown(e, item)
                          }}
                          min={0}
                          step={0.0001}
                          naked
                        />
                      </td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="number"
                          name={`details.resources[${resourceIdx}].quantityCalc`}
                          value={resource.quantityCalc}
                          onChange={(e) => {
                            this.onInputChange(`details.resources[${resourceIdx}].normModifier`, null, item, false);

                            // Recalculate norm on quantity change
                            const newNormCalc = e.value / item.quantityCalc;
                            this.onInputChange(`details.resources[${resourceIdx}].normCalc`, newNormCalc, item, false);

                            for (const param of details.parameters) {
                              if (!param.resources) continue;
                              for (const paramResource of param.resources) {
                                if (paramResource.code !== resource.code) continue;
                                paramResource.normCalc = 0;
                              }
                            }

                            this.onInputChange(e.name, e.value, item);
                          }}
                          onKeyDown={e => {
                            this.onInputKeyDown(e, item)
                          }}
                          min={0}
                          step={0.0001}
                          naked
                        />
                      </td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="number"
                          name={`details.resources[${resourceIdx}].priceCalc`}
                          value={resource.priceCalc}
                          className={resource.priceCalc === 0 && classes.inputError}
                          onChange={(e) => {
                            // Apply same value to parameter resources with same code
                            for (const param of details.parameters) {
                              if (!param.resources) continue;
                              for (const paramResource of param.resources) {
                                if (paramResource.code !== resource.code) continue;
                                paramResource.priceCalc = e.value;
                              }
                            }
                            
                            this.onInputChange(e.name, e.value, item);
                          }}
                          onKeyDown={e => {
                            this.onInputKeyDown(e, item)
                          }}
                          min={0}
                          step={0.0001}
                          naked
                        />
                      </td>
                      <td className={classes.textRight}>{twoOrMoreDecimals(resource.amountCalc)}</td>
                    </tr>
                  ))}

                  {details.parameters.length ? (
                    <React.Fragment>
                      <tr>
                        <td colSpan={7} className={classes.parametersHeaderRow}>
                          {t('Pokyčio parametrai')}
                        </td>
                      </tr>

                      {_.map(details.parameters, (parameter, paramIdx) => (
                        <React.Fragment key={`${parameter.code}-parameter`}>
                          <tr onClick={(e) => this.onParameterRowClick(e, parameter)}>
                            <td className={classes.parameterCode}>{parameter.code}</td>
                            <td colSpan={3}>{parameter.title}</td>
                            <td style={{ padding: '2px 8px' }}>
                              <FormField2
                                type="number"
                                name={`details.parameters[${paramIdx}].quantityCalc`}
                                value={parameter.quantityCalc || 0}
                                onChange={(e) => {
                                  for (const resource of details.resources) {
                                    resource.normCalc = resource.norm;
                                    resource.normModifier = null;
                                  }

                                  for (const param of details.parameters) {
                                    if (!param.resources) continue;
                                    for (const paramResource of param.resources) {
                                      paramResource.normCalc = paramResource.norm;
                                    }
                                  }

                                  // if (e.value == 0) {
                                  //   if (details.titleCalc.includes(parameter.title)) {
                                  //     details.titleCalc = details.titleCalc/*.replace(parameter.title, '')*/;
                                  //   }
                                  // } else {
                                  //   if (!details.titleCalc.includes(parameter.title)) {
                                  //     details.titleCalc = `${details.titleCalc} ${parameter.title} ${e.value}`
                                  //   }
                                  // }

                                  this.onInputChange(e.name, e.value, item);
                                }}
                                onKeyDown={e => this.onInputKeyDown(e, item)}
                                min={0}
                                step={0.0001}
                                naked
                              />
                            </td>
                            <td colSpan={2} />
                          </tr>
                          {parameter.resources && parameter.expanded ? (
                            _.map(parameter.resources, (resource, paramResourceIdx) => (
                              <tr key={`${parameter.code}-${resource.code}-resource`}>
                                <td className={classes.textCenter}>{resource.code}</td>
                                <td style={{ padding: '2px 8px' }}>
                                  <FormField2
                                    type="text"
                                    name={`details.parameters[${paramIdx}].resources[${paramResourceIdx}].titleCalc`}
                                    value={resource.titleCalc}
                                    onChange={(e) => {
                                      // Apply same value to resources with same code
                                      for (const res of details.resources) {
                                        if (res.code !== resource.code) continue;
                                        res.titleCalc = e.value;
                                      }
                                      
                                      const needsRecalc = e.value === '';
                                      this.onInputChange(e.name, e.value, item, needsRecalc);
                                    }}
                                    onKeyDown={e => {
                                      this.onInputKeyDown(e, item);
                                    }}
                                    placeholder={`${t('Resurso pavadinimas')}...`}
                                    naked
                                    disabled={parameter.quantityCalc === 0}
                                  />
                                </td>
                                <td>{resource.unit}</td>
                                <td style={{ padding: '2px 8px' }}>
                                  <FormField2
                                    type="number"
                                    name={`details.parameters[${paramIdx}].resources[${paramResourceIdx}].normCalc`}
                                    value={resource.normCalc}
                                    onChange={(e) => {
                                      // this.onInputChange(`details.parameters[${paramIdx}].resources[${paramResourceIdx}].quantity`, null, item, true);

                                      this.onInputChange(e.name, e.value, item);
                                    }}
                                    onKeyDown={e => {
                                      this.onInputKeyDown(e, item);
                                    }}
                                    min={0}
                                    step={0.0001}
                                    naked
                                    disabled={parameter.quantityCalc === 0}
                                  />
                                </td>
                                <td style={{ padding: '2px 8px' }}>
                                  <FormField2
                                    type="number"
                                    name={`details.parameters[${paramIdx}].resources[${paramResourceIdx}].quantityCalc`}
                                    value={resource.quantityCalc}
                                    onChange={(e) => {
                                      // this.onInputChange(`details.parameters[${paramIdx}].resources[${paramResourceIdx}].normCalc`, null, item, true);
                                      // Recalculate norm on quantity change
                                      const newNormCalc = e.value / parameter.quantityCalc / item.quantityCalc;
                                      this.onInputChange(`details.parameters[${paramIdx}].resources[${paramResourceIdx}].normCalc`, newNormCalc, item, false);

                                      this.onInputChange(e.name, e.value, item);
                                    }}
                                    onKeyDown={e => {
                                      this.onInputKeyDown(e, item);
                                    }}
                                    min={0}
                                    step={0.0001}
                                    naked
                                    disabled={parameter.quantityCalc === 0}
                                  />
                                </td>
                                <td style={{ padding: '2px 8px' }}>
                                  <FormField2
                                    type="number"
                                    name={`details.parameters[${paramIdx}].resources[${paramResourceIdx}].priceCalc`}
                                    value={resource.priceCalc}
                                    onChange={(e) => {
                                      // Apply same value to resources with same code
                                      for (const res of details.resources) {
                                        if (res.code !== resource.code) continue;
                                        res.priceCalc = e.value;
                                      }

                                      this.onInputChange(e.name, e.value, item);
                                    }}
                                    onKeyDown={e => {
                                      this.onInputKeyDown(e, item)
                                    }}
                                    min={0}
                                    step={0.0001}
                                    naked
                                    disabled={parameter.quantityCalc === 0}
                                  />
                                </td>
                                <td className={classes.textRight}>{twoOrMoreDecimals(resource.amountCalc)}</td>
                              </tr>
                            ))
                          ) : null}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : null}
                </tbody>
              </table>
            ) : null}
          </CommonPanel>
        </div>

        <div>
          <Tabs value={tabValue[item.code]} onChange={(event, newValue) => handleTabChange(event, newValue, item.code)} variant="fullWidth" style={{ minHeight: 32, maxHeight: 38 }}>
            <Tab 
              {...a11yProps(0)}
              label={t('Kainos sudėtis')}
              style={{ marginRight: 10, backgroundColor: 'white', minHeight: 32, maxHeight: 38, opacity: 1 }}
              icon={this.renderExpandArrow(item.code, 0)}
              classes={{
                wrapper: classes.iconLabelWrapper,
                labelContainer: classes.labelContainer
              }}
            />
            <Tab 
              {...a11yProps(1)}
              label={t('Darbų sudėtis')}
              style={{ marginRight: 10, backgroundColor: 'white', minHeight: 32, maxHeight: 38, opacity: 1 }}
              icon={this.renderExpandArrow(item.code, 1)}
              classes={{
                wrapper: classes.iconLabelWrapper,
                labelContainer: classes.labelContainer
              }}
            />
            <Tab 
              {...a11yProps(2)}
              label={t('Koeficientai sąnaudoms')}
              style={{ marginRight: 10, backgroundColor: 'white', minHeight: 32, maxHeight: 38, opacity: 1 }}
              icon={this.renderExpandArrow(item.code, 2)}
              classes={{
                wrapper: classes.iconLabelWrapper,
                labelContainer: classes.labelContainer
              }}
            />
            <Tab 
              {...a11yProps(3)}
              label={t('Kainos nustatymai')}
              style={{ backgroundColor: 'white', minHeight: 32, maxHeight: 38, opacity: 1 }}
              icon={this.renderExpandArrow(item.code, 3)}
              classes={{
                wrapper: classes.iconLabelWrapper,
                labelContainer: classes.labelContainer
              }}
            />
          </Tabs>
          <TabPanel value={tabValue[item.code]} index={0} disabled={!item.totals}>
            { !!item.totals && <NormPriceComposition item={item} /> }
          </TabPanel>
          <TabPanel value={tabValue[item.code]} index={1} padding={1} disabled={!details.details} style={{ backgroundColor: 'white' }}>
            { details.details }
          </TabPanel>
          <TabPanel value={tabValue[item.code]} index={2} disabled={!details.coefficients}>
            { details.coefficients && details.coefficients.length ? (
              <NormCoefficients
                item={toJS(item)}
                onChange={(newDetails, instantRecalc = false) => {
                  item.details = { ...newDetails };

                  if (!instantRecalc) {
                    this.props.store.doRecalc(item);
                  } else {
                    // Cancel debouncer initiated by onInputChange
                    this.props.store.doRecalc.cancel();

                    this.props.store.recalculate(item);
                  }
                }}
              />
            ) : null }
          </TabPanel>
          <TabPanel value={tabValue[item.code]} index={3} disabled={!details.priceSettings}>
            { details.priceSettings ? (
              <NormPriceSettings
                item={item}
                fromEstimate={!!this.props.overridePriceSettings}
                onChange={(newDetails, instantRecalc = false) => {
                  item.details = { ...newDetails };

                  if (!instantRecalc) {
                    this.props.store.doRecalc(item);
                  } else {
                    // Cancel debouncer initiated by onInputChange
                    this.props.store.doRecalc.cancel();

                    this.props.store.recalculate(item);
                  }
                }}
              />
            ) : null }
          </TabPanel>
        </div>

      </React.Fragment>
    );

    return (
      <tr
        key={`${code}-details`}
        className={[this.props.classes.detailsRow, 'tree-details'].join(' ')}
      >
        <td />
        <td colSpan={5}>
          {contents}
        </td>
      </tr>
    )
  }

  unitToSymbol(unit) {
    switch (unit) {
      case 'percent':
        return '%';
      case 'coef':
        return '';
      case 'amount':
        return 'EUR';
      default:
        return '';
    }
  }

  renderFirstColumn({ code, lastLevel, expanded }) {
    const { classes } = this.props;
    return (
      <td className={classes.firstColumn} style={{ width: '20%' }}>
        {!lastLevel && <span className={classes.expander}>{expanded ? '-' : '+'}</span>}
        {code}
      </td>
    )
  }

  handleCollectionDownloadClick = async (e, code, version) => {
    const { t } = this.props;

    try {
      e.stopPropagation();
      const file = await this.props.store.downloadCollection(code, version);
      
      var bytes = new Uint8Array(file.content);
      const blob = new Blob([bytes], {type: file.contentType});
      const fileName = `${code} - ${file.fileName}`;
      blobToFileDownload(fileName, blob);
    } catch {
      toast(t('Failas nerastas'), 'error');
    }
  }

  renderTableRow(item, isLast) {
    let rows = [];
    const { t } = this.props;

    const mainRow = (
      <tr
        key={item.code}
        onClick={e => this.onTreeRowClick(e, item)}
        className={[
          item.expanded ? [this.props.classes.expandedRow, 'tree-expanded'].join(' ') : '', 
          `code-${item.code}`.replace('#', ''), 
          `tree-node level-${item.level} ${isLast ? 'level-last' : ''}`,
        ].join(' ')}
      >
        {this.renderFirstColumn(item)}
        <td
          style={item.hasZeroNormOrPrice ? { color: 'red', display: 'inlineBlock' } : { display: 'inlineBlock' }}
          title={item.hasZeroNormOrPrice ? t('Yra resursų su nenurodytomis kainomis ar normomis') : undefined}
        >
          {item.title}
          {item.level === 1 && item.code[0] !== 'F' ? 
            <Link title={t('Techninė dalis')} style={{ float: 'right' }}>
              <DescriptionIcon onClick={e => this.handleCollectionDownloadClick(e, item.code, this.props.store.version.code)} fontSize="small" style={{ verticalAlign: 'top' }} />
            </Link> 
            : null
          }
        </td>
        <td style={{ width: '10%', textAlign: 'center' }}>{item.unit}</td>
        {/* <td style={{ width: '10%', textAlign: 'center' }}>{item.quantityCalc}</td> */}
        <td style={{ width: '10%', padding: '2px 8px' }}>
          {item.lastLevel ? (
            <FormField2
              type="number"
              name="quantityCalc"
              value={item.quantityCalc || 0}
              onChange={(e) => {
                this.onInputChange(e.name, e.value, item);
              }}
              onKeyDown={e => {
                this.onInputKeyDown(e, item);
              }}
              min={0}
              step={0.0001}
              naked
            />
          ) : null}
        </td>

        <td style={{ width: '10%', textAlign: 'right' }}>{item.lastLevel ? twoOrMoreDecimals(_.get(item, 'totals.unitAmountPriceVat', 0)) : null}</td>
        <td style={{ width: '10%', textAlign: 'right' }}>{item.lastLevel ? twoOrMoreDecimals(_.get(item, 'totals.amountPriceVat', 0)) : null}</td>
      </tr>
    )
    rows.push(mainRow);

    if (item.expanded && !item.details) {
      const childRows = _.map(item.children, (child, idx) => this.renderTableRow(child, (idx + 1) === item.children.length && !child.expanded))
      rows = _.concat(rows, childRows);
    } else if (item.expanded && item.details) {
      const detailsRow = this.renderDetailsRow(item);
      rows.push(detailsRow);
    }

    return rows;
  }

  renderData() {
    const { data } = this.props.store;

    const rows = _.map(data, item => this.renderTableRow(item));
    // const rows = _.map(_.keys(data), key => this.renderTableRow(data[key]));

    return rows;
  }

  renderSearch() {
    const { version, type } = this.props.store;
    return (
      <NormSearch
        onSelect={this.onSearchResultSelected.bind(this)}
        inputStyle={{ marginTop: 5 }}
        version={version ? version.code : null}
        type={type}
        initialSearchString={this.props.initialSearchString}
      />
    );
  }

  renderVersions() {
    const { store, t, lockedVersionCode } = this.props;

    return (
      <NormVersionPicker
        value={lockedVersionCode || store.version?.code}
        name="version"
        onChange={({ value }) => store.setVersion(value)}
        label={t('Versija')}
        style={{ marginTop: 5, background: '#fff' }}
        type={store.type}
        disabled={lockedVersionCode}
        variant={'normsCalc'}
      />
    );
  }

  renderExportButton() {
    const { t, isEmbeded } = this.props;

    if (isEmbeded) return null;
    
    return (
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => {
          const exportPriceSettings = _.cloneDeep(this.props.store.priceSettings);
          this.setState({ showExportDialog: true, exportPriceSettings });
        }}
        style={{ marginTop: 8 }}
        disabled={!this.props.store.touchedItems.length || !ENABLE_NORMS_EXCEL_EXPORT}
      >
        {t('Eksportuoti į Excel')}
      </Button>
    )
  }

  renderTransferToEstimateButton() {
    const { t, isEmbeded } = this.props;
    if (isEmbeded) return null;

    return (
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => {
          if (!this.state.transferToEstimatePriceSettings.length) {
            const transferToEstimatePriceSettings = _.cloneDeep(this.props.store.priceSettings);
            this.setState({ showTransferToEstimateDialog: true, transferToEstimatePriceSettings });
          } else {
            this.setState({ showTransferToEstimateDialog: true});
          }
        }}
        style={{ marginTop: 8, marginRight: 10 }}
        disabled={!this.props.store.touchedItems.length || !this.state.hasEstimatesAccess}
      >
        {t('Perkelti į sąmatą')}
      </Button>
    )
  }

  renderPriceSettingsButton() {
    const { t, isEmbeded } = this.props;

    if (isEmbeded) return null;

    return (
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => this.setState({ showPriceSettingsDialog: true })}
        style={{ marginTop: 8, marginRight: 10 }}
      >
        {t('Kainos nustatymai')}
      </Button>
    )
  }

  renderResourcesButton() {
    const { t, isEmbeded } = this.props;
    if (isEmbeded) return null;

    return (
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => this.setState({ showResourceSearch: true })}
        style={{ marginTop: 8, marginRight: 10 }}
      >
        {t('Resursų paieška')}
      </Button>
    )
  }

  renderSelectionOutputButton() {
    const { t, isEmbeded, onSelectionChange, onCancel, store } = this.props;

    if (!isEmbeded || !onSelectionChange) return null;

    return (
      <>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => this.setState({ showRateImport: true, rateImportSelected: this.state.rateImportSelected.length == 0 ? toJS(store.touchedItems) : this.state.rateImportSelected })}
          style={{ marginTop: 8, backgroundColor: 'green', opacity: store.touchedItems.length ? 1 : 0.7 }}
          disabled={!store.touchedItems.length}
        >
          {t('Įkelti įkainius')}
        </Button>

        {onCancel && (
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onCancel()}
            style={{ marginTop: 8, marginLeft: 10 }}
          >
            {t('Atšaukti')}
          </Button>
        )}
      </>
    )
  }

  exportAllToggle(checked) {
    const { touchedItems } = this.props.store;

    _.forEach(touchedItems, item => {
      item.isExporting = checked;
    });
  }

  transferAllToggle(checked) {
    const { touchedItems } = this.props.store;

    _.forEach(touchedItems, item => {
      item.isTransfering = checked;
    });
  }

  submitExportToExcel(type) {
    const { exportPriceSettings } = this.state;
    this.props.store.exportToExcel(type, exportPriceSettings);

    // this.setState({
    //   showExportDialog: false,
    //   exportAnchorEl: null,
    //   exportStep: 1,
    //   exportPriceSettings: [],
    // });
  }

  renderExportDialog() {
    const { classes, store, t } = this.props;
    const { touchedItems, mismatchedItems } = store;
    const { exportStep } = this.state;
    const exporting = touchedItems.filter(i => i.isExporting);
    const disabled = !exporting.length;
    const allSelected = exporting.length === touchedItems.length;

    const stepUp = () => {
      setStep();
    }

    const stepBack = () => {
      setStep(false);
    }

    const setStep = (stepUp = true) => {
      let nextStep = stepUp ? (exportStep + 1) : (exportStep - 1); 
      if (nextStep === 3) {
        const mismatchCount = store.checkMismatchingItems();

        if(mismatchCount === 0) {
          nextStep = stepUp ? (nextStep + 1) : (nextStep - 1);
        }
      }
      
      this.setState({ exportStep: nextStep});
    }

    const onChange = ({ name, value }, idx) => {
      const { exportPriceSettings } = this.state;
      exportPriceSettings[idx][name] = value;
      
      this.setState({ exportPriceSettings });
    };

    const onChangeMismatchedItem = ({ name, value }, idx) => {
      mismatchedItems[idx][name] = value;
    };

    const onClose = (reset = false) => {
      if (reset) {
        this.setState({
          showExportDialog: false,
          exportAnchorEl: null,
          exportStep: 1,
          exportPriceSettings: [],
        });
      } else {
        this.setState({ showExportDialog: false, exportStep: 1 });
      }
    }

    const menu = (
      <>
        <Button
          key="back"
          onClick={() => onClose()}
          color="primary"
          variant="contained"
          size="small"
        >
          {t('Grįžti')}
        </Button>

        <Button
          key="back"
          onClick={stepBack}
          color="primary"
          disabled={exportStep === 1}
          variant="contained"
          size="small"
        >
          {t('Atgal')}
        </Button>

        <Button
          key="step"
          onClick={stepUp}
          color="primary"
          disabled={disabled || exportStep > 3}
          variant="contained"
          size="small"
        >
          {t('Tęsti')}
        </Button>

        <Button
          key="close"
          onClick={() => onClose(true)}
          color="secondary"
          style={{backgroundColor: 'darkred'}}
          variant="contained"
          size="small"
        >
          {t('Uždaryti')}
        </Button>
      </>
    )

    let step;
    switch (exportStep) {
      case 1:
        step = (
          <>
            <Typography variant="body1" className={classes.exportDialogText}>
              {t('Pasirinkite eksportuojamus įrašus')}:
            </Typography>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>
                    <input
                      type="checkbox"
                      onChange={e => this.exportAllToggle(e.target.checked)} 
                      checked={allSelected}
                    />
                  </th>
                  <th style={{ width: '15%' }}>
                    {t('Kodas')}
                  </th>
                  <th>
                    {t('Pavadinimas')}
                  </th>
                  <th>
                    {t('Vertė')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {_.map(touchedItems, (item, idx) => {
                  let title = _.get(item, 'details.titleCalc', item.title);
                  title = item.code && title ? title.replace(item.code, '').trim() : title;

                  return (
                    <tr key={`${item.code}-${idx}`}>
                      <td>
                        <input type="checkbox" onChange={e => item.isExporting = e.target.checked} checked={item.isExporting} />
                      </td>
                      <td>
                        {item.code}
                      </td>
                      <td>
                        {title}
                      </td>
                      <td>
                        {twoOrMoreDecimals(_.get(item, 'totals.amountPriceVat', 0))}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )
        break;

      case 2:
        step = (
          <>
            <Typography variant="body1" className={classes.exportDialogText}>
              {t('Eksportuojamiems įrašams pritaikomi kainos nustatymai')}:
            </Typography>
            <table className={this.props.classes.table}>
              <thead>
                <tr>
                  <th style={{ width: '12%' }}>{t('Kodas')}</th>
                  <th>{t('Pavadinimas')}</th>
                  <th style={{ width: '12%' }}>{t('Matas')}</th>
                  <th style={{ width: '12%' }}>{t('Rodiklio reikšmė')}*</th>
                </tr>
              </thead>
              <tbody>
                {_.map(this.state.exportPriceSettings, (setting, settingIdx) => (
                  <tr key={setting.code}>
                    <td style={{ textAlign: 'center' }}>{setting.code ? setting.code.toUpperCase() : '-'}</td>
                    <td style={{ padding: '2px 8px' }}>
                      {setting.title}
                    </td>
                    <td style={{ padding: '2px 8px' }}>
                      <FormField2
                        type="select"
                        options={[
                          { label: '%', value: 'percent' }, 
                          { label: t('Koeficientas'), value: 'coef' }, 
                          { label: `${t('Suma')}, EUR`, value: 'amount' }
                        ]}
                        name="unit"
                        value={setting.unit}
                        onChange={(e) => {
                          const valBefore = setting.unit;
                          const valNew = e.value;
                          let value;
                          
                          if (valBefore === 'percent' && valNew === 'coef') {
                            value = value / 100;
                          } else if (valBefore === 'coef' && valNew === 'percent') {
                            value = value * 100;
                          } else if (valNew === 'amount') {
                            value = 0;
                          }

                          onChange({ name: e.name, value }, settingIdx);
                        }}
                        naked
                        style={{ margin: 0 }}
                      />
                    </td>
                    <td style={{ padding: '2px 8px' }}>
                      <FormField2
                        type="number"
                        name="value"
                        value={setting.value}
                        onChange={e => onChange(e, settingIdx)}
                        min={0}
                        step={0.0001}
                        naked
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )
        break;

      case 3:
        step = (
          <>
            <Typography variant="body1" className={classes.exportDialogText}>
              {t('Suvienodinkite nesutampančias resursų kainas')}:
            </Typography>

            <table className={this.props.classes.table}>
              <thead>
                <tr>
                  <th style={{ width: '12%' }}>{t('Kodas')}</th>
                  <th>{t('Pavadinimas')}</th>
                  <th style={{ width: '12%' }}>{t('Matas')}</th>
                  <th style={{ width: '12%' }}>{t('Kaina')}</th>
                </tr>
              </thead>
              <tbody>
                {mismatchedItems.length ? _.map(mismatchedItems, (item, itemIdx) => (
                  <tr key={item.code}>
                    <td style={{ textAlign: 'center' }}>{item.code ? item.code.toUpperCase() : '-'}</td>
                    <td style={{ padding: '2px 8px' }}>
                      {item.title}
                      {item.values.length && (
                        <>
                          <br />
                          <small><strong>{t('Įvestos reikšmės')}:</strong></small>
                        </>
                      )}
                      {item.values.length && item.values.map(value => (
                        <>
                          <br />
                          <small>
                            {value.code} {value.title}: <strong>{value.value} EUR</strong>
                          </small>
                        </>
                      ))}
                    </td>
                    <td style={{ padding: '2px 8px' }}>
                      {item.unit}
                    </td>
                    <td style={{ padding: '2px 8px' }}>
                      <FormField2
                        type="number"
                        name="priceCalc"
                        value={item.priceCalc}
                        onChange={e => onChangeMismatchedItem(e, itemIdx)}
                        min={0}
                        step={0.0001}
                        naked
                      />
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center', padding: '2px 8px' }}>
                      {t('Visos resursų kainos sutampa')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )
        break;

      case 4:
        step = (
          <>
            <Typography variant="body1" className={classes.exportDialogText}>
              {t('Pasirinkite eksporto tipą')}:
            </Typography>
            <List component="nav">
              <ListItem button onClick={() => this.submitExportToExcel('rates')}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={t('Įkainiai')} secondary={t('Kainos apskaičiuojamos pagal vienetinius darbus, papildomi priskaitymai pateikiami bendri visai įkainių grupei')}/>
              </ListItem>
              <ListItem button onClick={() => this.submitExportToExcel('ratesWithSurcharges')}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={t('Įkainiai su priskaitymais')} secondary={t('Kainos apskaičiuojamos pagal vienetinius darbus, papildomi priskaitymai įskaičiuoti darbo vieneto kainoje')}/>
              </ListItem>
              <ListItem button onClick={() => this.submitExportToExcel('ratesResourcesWithSurcharges')}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={t('Įkainiai ir resursai su priskaitymais')} secondary={t('Kainos apskaičiuojamos pagal vienetinius darbus, papildomi priskaitymai įskaičiuoti darbo vieneto kainoje. Darbų įkainiai pateikiami su resursais, jų kiekiais ir kainomis')}/>
              </ListItem>
              <ListItem button onClick={() => this.submitExportToExcel('general')}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={t('Detalus')} secondary={t('Formuojama detali įkainių struktūra integracijai su kitomis sistemomis')}/>
              </ListItem>
            </List>
          </>
        )
        break;

      default:
        step = <>...</>
    }

    return (
      <CommonDialog
        visible={this.state.showExportDialog}
        title={t('Skaičiavimų eksportavimas Excel formatu')}
        onClose={onClose}
        actions={[
          menu,
        ]}
        scroll="paper"
        // maxWidth="xs"
      >
        <div className={classes.dialogContentWrap}>
          {step}
        </div>
      </CommonDialog>
    )
  }

  renderTransferToEstimateDialog() {
    const { classes, store, t } = this.props;
    const { touchedItems } = store;
    const { transferToEstimateStep } = this.state;
    const transfering = touchedItems.filter(i => i.isTransfering);
    const allSelected = transfering.length === touchedItems.length;

    const handleTransfer = async () => {
      store.setLoading(true);
      try {
        const {data} = await api.post(`/services/norms/transfer-calc`, {
          transferToEstimatePriceSettings: this.state.transferToEstimatePriceSettings,
          newEstimateTitle: this.state.newEstimateTitle,
          newProjectTitle: this.state.newProjectTitle,
          newProjectCode: this.state.newProjectCode,
          version: store.version,
          items: transfering,
        });
        toast('Skaičiavimas perkeltas į sąmatą', 'success');
        history.push(`/projects/${data.projectId}/objects/${data.estimateObjectId}`);
      } catch (err) {
        console.log(err);
        toast('Nepavyko perkelti skaičiavimo į sąmatą', 'error');
      }
      store.setLoading(false);
    }

    const onChange = ({ name, value }, idx) => {
      const { transferToEstimatePriceSettings } = this.state;
      transferToEstimatePriceSettings[idx][name] = value;
      
      this.setState({ transferToEstimatePriceSettings });
    };

    const stepUp = () => {
      setStep();
    }

    const stepBack = () => {
      setStep(false);
    }

    const setStep = (stepUp = true) => {
      const nextStep = stepUp ? (transferToEstimateStep + 1) : (transferToEstimateStep - 1);
      this.setState({ transferToEstimateStep: nextStep});
    }

    const onClose = (reset = true) => {
      if (reset) {
        this.setState({
          showTransferToEstimateDialog: false,
          transferToEstimateAnchorEl: null,
          transferToEstimateStep: 1,
          transferToEstimatePriceSettings: [],
          newEstimateTitle: null,
          newProjectTitle: null,
          newProjectCode: null,
        });
      }

      if (!reset) {
        this.setState({
          showTransferToEstimateDialog: false,
          transferToEstimateAnchorEl: null,
        })
      }
    }

    const menu = (
      <>
        <Button
          key="back"
          onClick={() => onClose(false)}
          color="primary"
          variant="contained"
          size="small"
        >
          {t('Į pradžią')}
        </Button>

        <Button
          key="back"
          onClick={stepBack}
          color="primary"
          disabled={transferToEstimateStep === 1}
          variant="contained"
          size="small"
        >
          {t('Atgal')}
        </Button>

        {transferToEstimateStep < 3 ?
            <Button
              key="step"
              onClick={stepUp}
              color="primary"
              disabled={!transfering.length}
              variant="contained"
              size="small"
            >
              {t('Tęsti')}
            </Button>
          : <>
            <Button
              key="transfer"
              onClick={handleTransfer}
              color="primary"
              variant="contained"
              size="small"
              disabled={!this.state.newProjectTitle || !this.state.newEstimateTitle}
            >
              {t('Tęsti')}
            </Button></>
        }

        {transferToEstimateStep < 5 &&
          <Button
            key="close"
            onClick={() => onClose()}
            color="secondary"
            style={{backgroundColor: 'darkred'}}
            variant="contained"
            size="small"
          >
            {t('Uždaryti')}
          </Button>
        }
      </>
    )

    let step;
    switch (transferToEstimateStep) {
      case 1:
        step = (
          <>
            <Typography variant="body1" className={classes.exportDialogText}>
              {t('Pasirinkite įrašus perkėlimui')}:
            </Typography>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>
                    <input
                      type="checkbox"
                      onChange={e => this.transferAllToggle(e.target.checked)} 
                      checked={allSelected}
                    />
                  </th>
                  <th style={{ width: '15%' }}>
                    {t('Kodas')}
                  </th>
                  <th>
                    {t('Pavadinimas')}
                  </th>
                  <th>
                    {t('Vertė')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {_.map(touchedItems, (item, idx) => {
                  let title = _.get(item, 'details.titleCalc', item.title);
                  title = item.code && title ? title.replace(item.code, '').trim() : title;

                  return (
                    <tr key={`${item.code}-${idx}`}>
                      <td>
                        <input type="checkbox" onChange={e => item.isTransfering = e.target.checked} checked={item.isTransfering} />
                      </td>
                      <td>
                        {item.code}
                      </td>
                      <td>
                        {title}
                      </td>
                      <td>
                        {twoOrMoreDecimals(_.get(item, 'totals.amountPriceVat', 0))}
                      </td>
                    </tr>
                  )
                })}
              <tr>
              </tr>
              </tbody>
            </table>
          </>
        )
        break;
      case 2:
        step = (
          <>
            <div>
              <Typography variant="body1" className={classes.exportDialogText}>
                {t('Kainos nustatymai perkeliamiems įrašams')}:
              </Typography>
              <table className={this.props.classes.table}>
                <thead>
                  <tr>
                    <th style={{ width: '12%' }}>{t('Kodas')}</th>
                    <th>{t('Pavadinimas')}</th>
                    <th style={{ width: '12%' }}>{t('Matas')}</th>
                    <th style={{ width: '12%' }}>{t('Rodiklio reikšmė')}*</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(this.state.transferToEstimatePriceSettings, (setting, settingIdx) => (
                    <tr key={setting.code}>
                      <td style={{ textAlign: 'center' }}>{setting.code ? setting.code.toUpperCase() : '-'}</td>
                      <td style={{ padding: '2px 8px' }}>
                        {setting.title}
                      </td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="select"
                          options={[
                            { label: '%', value: 'percent' }, 
                            { label: t('Koeficientas'), value: 'coef' }, 
                            { label: `${t('Suma')}, EUR`, value: 'amount' }
                          ]}
                          name="unit"
                          value={setting.unit}
                          onChange={(e) => {
                            const valBefore = setting.unit;
                            const valNew = e.value;
                            let value;
                            
                            if (valBefore === 'percent' && valNew === 'coef') {
                              value = value / 100;
                            } else if (valBefore === 'coef' && valNew === 'percent') {
                              value = value * 100;
                            } else if (valNew === 'amount') {
                              value = 0;
                            }

                            onChange({ name: e.name, value }, settingIdx);
                          }}
                          naked
                          style={{ margin: 0 }}
                        />
                      </td>
                      <td style={{ padding: '2px 8px' }}>
                        <FormField2
                          type="number"
                          name="value"
                          value={setting.value}
                          onChange={e => onChange(e, settingIdx)}
                          min={0}
                          step={0.0001}
                          naked
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> 
          </>
        )
        break;
      case 3: 
        step = (
          <>
            <Typography variant="body1" style={{marginBottom: 0, marginLeft: 5}} className={classes.exportDialogText}>
              {t('Naujo projekto duomenys')}:
            </Typography>
            <FormField2 
              name="text"
              onChange={({ value }) => this.setState({ newProjectCode: value })}
              value={this.state.newProjectCode}
              label={t('Projekto kodas')}
              style={{ maxWidth: 300 }}
            />
            <FormField2 
              name="text"
              onChange={({ value }) => this.setState({ newProjectTitle: value })}
              value={this.state.newProjectTitle}
              label={t('Projekto pavadinimas')}
              style={{ maxWidth: 300 }}
              required
            />

            <hr style={{margin: '15px 0px'}}/>

            <Typography variant="body1" style={{marginBottom: 0, marginLeft: 5}} className={classes.exportDialogText}>
              {t('Naujo skaičiavimų objekto duomenys')}:
            </Typography>
            <FormField2 
              name="text"
              onChange={({ value }) => this.setState({ newEstimateTitle: value })}
              value={this.state.newEstimateTitle}
              label={t('Skaičiavimų objekto pavadinimas')}
              style={{ width: 300 }}
              required
            />
          </>
        )
        break;

      default:
        step = <>...</>
    }

    return (
      <CommonDialog
        visible={this.state.showTransferToEstimateDialog}
        title={t('Skaičiavimų perkėlimas į sąmatą')}
        onClose={onClose}
        actions={[
          menu,
        ]}
        scroll="paper"
        // maxWidth="xs"
      >
        <div className={classes.dialogContentWrap}>
          {step}
        </div>
      </CommonDialog>
    )
  }

  renderPriceSettingsDialog() {
    const { classes, store, t } = this.props;
    const { priceSettings } = store;
    const tempPriceSettings = !this.state.tempPriceSettings.length
      ? _.cloneDeep(priceSettings)
      : this.state.tempPriceSettings;

    const loadDefaults = async () => {
      await store.loadPriceSettings();
      this.setState({ tempPriceSettings: _.cloneDeep(store.priceSettings) });
    }

    const onChange = ({ name, value }, idx) => {
      !this.state.tempPriceSettings.length && this.setState({ tempPriceSettings: _.cloneDeep(priceSettings) });
      priceSettings[idx][name] = value;
    }

    const onSave = async () => {
      this.state.recalcWithPriceSettings && await store.recalcWithPriceSettings();

      this.setState({
        showPriceSettingsDialog: false,
        showResourceSearch: false,
        tempPriceSettings: [],
        recalcWithPriceSettings: false,
      });
    }

    const onClose = () => {
      store.setPriceSettings(tempPriceSettings);
      this.setState({
        showPriceSettingsDialog: false,
        showResourceSearch: false,
        tempPriceSettings: [],
        recalcWithPriceSettings: false,
      });
    }

    const actions = (
      <React.Fragment key="export">
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={loadDefaults}
          color="primary"
          size="small"
        >
          {t('Atstatyti pirmines reikšmes')}
        </Button>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={onSave}
          color="primary"
          variant="contained"
          size="small"
        >
          {t('Išsaugoti parametrus')}
        </Button>
      </React.Fragment>
    );

    return (
      <CommonDialog
        visible={this.state.showPriceSettingsDialog}
        title={t('Kainos nustatymo parametrai')}
        onClose={onClose}
        actions={[
          actions,
        ]}
        // maxWidth="xs"
      >
        <div className={classes.dialogContentWrap}>

          <table className={this.props.classes.table}>
            <thead>
              <tr>
                <th style={{ width: '12%' }}>{t('Kodas')}</th>
                <th>{t('Pavadinimas')}</th>
                <th style={{ width: '12%' }}>{t('Matas')}</th>
                <th style={{ width: '12%' }}>{t('Rodiklio reikšmė')}*</th>
              </tr>
            </thead>
            <tbody>
              {_.map(priceSettings, (setting, settingIdx) => (
                <tr key={setting.code}>
                  <td style={{ textAlign: 'center' }}>{setting.code ? setting.code.toUpperCase() : '-'}</td>
                  <td style={{ padding: '2px 8px' }}>
                    <FormField2
                      type="text"
                      name="title"
                      value={setting.title}
                      onChange={e => onChange(e, settingIdx)}
                      placeholder={`${t('Rodiklio pavadinimas')}...`}
                      naked
                    />
                  </td>
                  <td style={{ padding: '2px 8px' }}>
                    <FormField2
                      type="select"
                      options={[
                        { label: '%', value: 'percent' }, 
                        { label: t('Koeficientas'), value: 'coef' }, 
                        { label: `${t('Suma')}, EUR`, value: 'amount' }
                      ]}
                      name="unit"
                      value={setting.unit}
                      onChange={(e) => {
                        const valBefore = setting.unit;
                        const valNew = e.value;
                        let value;
                        
                        if (valBefore === 'percent' && valNew === 'coef') {
                          value = value / 100;
                        } else if (valBefore === 'coef' && valNew === 'percent') {
                          value = value * 100;
                        } else if (valNew === 'amount') {
                          value = 0;
                        }

                        onChange({ name: e.name, value }, settingIdx);
                      }}
                      naked
                      style={{ margin: 0 }}
                    />
                  </td>
                  <td style={{ padding: '2px 8px' }}>
                    <FormField2
                      type="number"
                      name="value"
                      value={setting.value}
                      onChange={e => onChange(e, settingIdx)}
                      min={0}
                      step={0.0001}
                      naked
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div style={{ marginTop: 8, textAlign: 'right' }}>
            <FormField2
              label={t('Perskaičiuoti esamus skaičiavimus pritaikant bendrus kainos nustatymus')}
              type="checkbox"
              name="recalcWithPriceSettings"
              value={this.state.recalcWithPriceSettings}
              onChange={(e) => this.setState({ [e.name]: e.value })}
              naked
              style={{ padding: 0 }}
            />
          </div>

        </div>
      </CommonDialog>
    )
  }

  renderService() {
    const { classes, store, t, isEmbeded } = this.props;

    return (
      <>
        <div className={classes.wrap}>

          <div ref={this.elRef} className="sticky-table-wrap" style={{ paddingTop: this.state.paddingTop }}>
            
            <div className="sticky-table-header">
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      {this.renderSearch()}
                    </Grid>
                    <Grid item xs={12} md={2}>
                      {this.renderVersions()}
                    </Grid>
                    <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                      {this.renderTransferToEstimateButton()}
                      {this.renderPriceSettingsButton()}
                      {this.renderResourcesButton()}
                      {this.renderExportButton()}
                      {this.renderSelectionOutputButton()}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.stickyTableWrap}>
                  <table className={classes.table} style={{ tableLayout: 'fixed' }}>
                    <thead>
                      <tr style={{ height: 46 }}>
                        <th style={{ width: '20%' }}>{t('Darbo kodas')}</th>
                        <th>{t('Pavadinimas')}</th>
                        <th style={{ width: '10%' }}>{t('Matas')}</th>
                        <th style={{ width: '10%' }}>{t('Kiekis')}</th>
                        <th style={{ width: '10%' }}>{t('Darbo vnt. kaina')}</th>
                        <th style={{ width: '10%' }}>{t('Suma')}</th>
                      </tr>
                    </thead>
                  </table>
                </Grid>
              </Grid>
            </div>

            <div className={classes.tableWrap} style={isEmbeded ? { height: '400px', overflow: 'auto', scrollBehavior: 'smooth' } : {}}>
              <table className={[classes.table, 'norms-tree'].join(' ')} style={{ tableLayout: 'fixed' }}>
                <tbody>
                  {this.renderData()}
                </tbody>
              </table>
            </div>
          </div>

        </div>

        {this.renderPriceSettingsDialog()}
        {this.renderExportDialog()}
        {this.renderRateSearch()}
        {this.renderTransferToEstimateDialog()}
        {this.renderRateImportSelect()}
      </>
    )
  }

  renderTrial() {

    return (
      <TrialHandler
        service={SERVICE_CODE}
      />
    )
  }

  renderTrialMessage() {
    const { t } = this.props;

    return EDU_MODE ? null : (
      <div style={{ margin: 10 }}>
        <Alert 
          variant="filled"
          severity="info"
          // onClose={() => this.setState({ showTrialMessage: false })}
        >
          {t('Jūs naudojate bandomąją (laikinai nemokamą) paslaugos versiją.')}
        </Alert>
      </div>
    )
  }

  renderRateSearch = () => {
    const { t } = this.props;

    const onClose = () => {
      this.setState({
        showResourceSearch: false,
      });
    }

    return (
      <>
        <CommonDialog
          visible={this.state.showResourceSearch}
          title={t('Resursų paieška')}
          onClose={onClose}
          maxWidth="md"
        >
          <ResourceSearch
            onSelect={() => null}
            version={this.props.store.version}

          />
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onClose()}
            style={{ marginTop: 8, float: 'right' }}
          >
            {t('Uždaryti')}
          </Button>
        </CommonDialog>
      </>
    )
  }

  renderRateImportSelect() {
    const { classes, t, onSelectionChange } = this.props;
    const items = toJS(this.props.store.touchedItems);

    const onClose = () => {
      this.setState({
        showRateImport: false,
      });
    }

    const handleSelect = (selectedItem) => {
      const index = this.state.rateImportSelected.findIndex(item => item.code == selectedItem.code);

      if (index == -1) {
        const rateItems = this.state.rateImportSelected;
        rateItems.push(selectedItem);
        this.setState({ rateImportSelected: rateItems });
      } else {
        const rateItems = this.state.rateImportSelected;
        rateItems.splice(index, 1);
        this.setState({ rateImportSelected: rateItems });
      }
    }

    const selectAll = () => {
      if (items.length == this.state.rateImportSelected.length) {
        this.setState({ rateImportSelected: [] });
      } else {
        this.setState({ rateImportSelected: items });
      }
    }

    return (
      <>
        <CommonDialog
          visible={this.state.showRateImport}
          title={t('Įkainių įkėlimas')}
          onClose={onClose}
          maxWidth="md"
        >
        <Typography variant="body1" className={classes.exportDialogText}>
          {t('Pasirinkite įkainius')}:
        </Typography>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={() => selectAll()}
                  checked={items.length == this.state.rateImportSelected.length || false}
                />
              </th>
              <th style={{ width: '15%' }}>
                {t('Kodas')}
              </th>
              <th>
                {t('Pavadinimas')}
              </th>
              <th>
                {t('Vertė')}
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(items, (item, idx) => {
              let title = _.get(item, 'details.titleCalc', item.title);
              title = item.code && title ? title.replace(item.code, '').trim() : title;

              return (
                <tr key={item.code}>
                  <td style={{ paddingLeft: 10 }}>
                    <input 
                      type="checkbox" 
                      onChange={() => handleSelect(item)} 
                      checked={this.state.rateImportSelected.findIndex(selectedItem => selectedItem.code == item.code) != -1 || false}
                    />
                  </td>
                  <td>
                    {item.code}
                  </td>
                  <td>
                    {title}
                  </td>
                  <td>
                    {twoOrMoreDecimals(_.get(item, 'totals.amountPrice', 0))}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => onSelectionChange(toJS(this.state.rateImportSelected))}
            style={{ marginTop: 8, float: 'right' }}
          >
            {t('Įkelti')}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => onClose()}
            style={{ marginTop: 8, marginRight: 8, float: 'right' }}
          >
            {t('Uždaryti')}
          </Button>
        </CommonDialog>
      </>
    )
  }

  render() {
    const { isTrial, showTrialMessage } = this.state;
    const { t, isEmbeded } = this.props;

    const view = !this.state.isTrial ? this.renderService() : this.renderTrial();

    if (isEmbeded) return view;

    return (
      <NormsCalc
        title={t('Statybos darbų kainos skaičiavimai')}
        beforeMainContent={!isTrial && showTrialMessage && this.renderTrialMessage()}
      >
        {view}
      </NormsCalc>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(NormCalcTreeTableContainer));