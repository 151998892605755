import DocumentList from './components/DocumentList';
import DocumentDetails from './components/DocumentDetails';

export default [
  {
    key: 'list',
    component: DocumentList,
    path: '/documents',
    public: false,
  },
  {
    key: 'create',
    component: DocumentDetails,
    path: '/documents/create',
    public: false,
  },
  {
    key: 'edit',
    component: DocumentDetails,
    path: '/documents/:id',
    public: false,
  }
];