import _ from 'lodash';
import * as dataService from 'views/Projects/shared/_services';

const projectOptions = async () => {
  const projects = await dataService.getAll();

  return _.map(projects, project => ({ 
    value: project._id, 
    label: `${project.code} - ${project.title}`,
  }));
};

const fields  = [
  {
    name: 'project',
    label: 'Priklauso projektui',
    type: 'select',
    options: projectOptions,
    required: true,
  },
  {
    name: 'title',
    label: 'Pavadinimas',
    type: 'text',
    required: true,
  },
  {
    name: 'type',
    label: 'Tipas',
    type: 'text',
  },
  {
    name: 'size',
    label: 'Dydis',
    type: 'number',
  },
 
];

export default fields;