import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Typography,
  Button,
  withStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';

import { history } from 'utils/history';
import { EDU_MODE } from 'utils/config';
import { toast, getCurrentAccount, setActiveAccount } from 'utils/helpers';
import { CommonPanel } from 'components';


import styles from './styles';

class Container extends React.Component {
  state = {
    currentAccount: null,
  }

  componentDidMount() {
    const { t } = this.props;
    const currentAccount = getCurrentAccount();

    const isCompanyAdmin = _.get(currentAccount, 'company.owner') == currentAccount._id;
    this.setState({ currentAccount, isCompanyAdmin });

    if (!this.props.store.setService(this.props.service)) {
      toast(t('Paslauga nerasta'), 'error');
      history.push('/');
      return;
    }
    this.props.store.getPreviousTrial(this.props.service);
  }

  componentWillUnmount() {
    this.setState({ currentAccount: null });
  }

  async submitTrial() {
    const { t } = this.props;

    if (!window.confirm(t('Ar tikrai norite pradėti naudoti bandomają versiją?'))) return;
    
    try {
      await this.props.store.submitTrial(this.props.service);
      toast(t('Paslauga aktyvuota'), 'success');
      
      // Reload permissions
      const accountId = this.state.currentAccount._id;
      const showToast = false;
      const redirectTo = window.location.pathname;
      await setActiveAccount(accountId, showToast, redirectTo, true);
    } catch (err) {
      toast(t('Nepavyko aktyvuoti paslaugos'), 'error');
    }
  }

  renderTrialSubmit() {
    const { t, store: { service } } = this.props;

    return (
      <>
        <Typography>{EDU_MODE ? t('Neaktyvavote paslaugos') : t('Neturite įsigiję paslaugos')} <strong>{t(service.title)}</strong></Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={this.submitTrial.bind(this)}
          style={{ marginTop: 20 }}
        >
          {EDU_MODE ?  t('Aktyvuoti paslaugą') : t('Išbandyti nemokamai 10 dienų')}
        </Button>
      </>
    )
  }

  renderTrialAlreadyUsed() {
    const { t, store } = this.props;
    const { service, companyAdmin } = store;

    return (
      <>
        <Typography>{t('Jūs jau pasinaudojote bandomaja paslaugos versija')} <strong>{service.title}</strong></Typography>

        {this.state.isCompanyAdmin ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/orders/create')}
            style={{ marginTop: 20 }}
          >
            {t('Užsakyti paslaugą')}
          </Button>
        ) : (
          <>
            <Typography style={{ marginTop: 20 }}>
              {t('Dėl paslaugų užsakymo kreipkitės į savo įmonės paskyros administratorių')}:
            </Typography>
            <Typography style={{ marginTop: 20, fontWeight: 'bold' }}>
              {_.get(companyAdmin, 'user.fullName')} {_.get(companyAdmin, 'user.phone')} {_.get(companyAdmin, 'user.email')}
            </Typography>
          </>
        )}
      </>
    );
  }

  render() {
    const { t, store } = this.props;
    const { prevTrial, service } = store;
    if (!service) return null;

    return (
      <CommonPanel
        title={t('Paslaugos bandomoji versija')}
        rootStyle={{ maxWidth: 400 }}
      >
        {!prevTrial ? this.renderTrialSubmit() : this.renderTrialAlreadyUsed()}
      </CommonPanel>
    )
  }
}

export default compose(withStyles(styles), withTranslation())(observer(Container));