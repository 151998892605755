import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withStyles, Typography, Button, Grid } from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import { CommonPanel, NewsWidget } from 'components';
import { getCurrentAccount } from 'utils/helpers';
import { history } from 'utils/history';
import { EDU_MODE } from 'utils/config';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
    // padding: '0px',
  },
  item: {
    height: '100%'
  },
  newsWrap: {
    maxHeight: 450,
    overflowY: 'auto'
  }
});

class Dashboard extends Component {
  state = {
    account: {}
  };

  componentDidMount() {
    const account = getCurrentAccount();

    this.setState({ account });
  }

  componentWillUnmount() {
    this.setState({ account: {} });
  }

  render() {
    const { classes, t } = this.props;

    return (
      <DashboardLayout title={t('Apžvalga')}>
        <div className={classes.root} style={{ maxWidth: '1200px' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CommonPanel>
                <Typography variant="body2">
                  <strong>{t('Sveiki')},</strong>
                  <br />
                  <strong>
                    {t(
                      'Jūs prisijungėte prie statybos normatyvų ir kainynų elektroninės žinių bazės ir paslaugų sistemos'
                    )}{' '}
                    <a target="_blank" href="https://bimgates.lt">
                      bimgates.lt
                    </a>
                  </strong>
                </Typography>

                <Typography variant="body2" style={{ marginTop: 20 }}>
                  {t(
                    'Darbui su sistema pasirinkite Jūsų sukurtą paskyrą arba sukurkite naują'
                  )}
                </Typography>

                <Grid container spacing={2} style={{ marginTop: 5 }}>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        window.document.getElementById('li-accounts').click()
                      }>
                      {t('Mano paskyros')}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => history.push('/companies/join')}>
                      {t('Sukurti paskyrą')}
                    </Button>
                  </Grid>
                </Grid>

                <Typography variant="body2" style={{ marginTop: 30 }}>
                  <strong>{t('Informacija sistemos vartotojams')}:</strong>
                  {!EDU_MODE ? (
                    <>
                      <br />
                      {t(
                        'Vartotojams, prisijungusiems prie sistemos ir susikūrusiems paskyrą, suteikiama teisė laikinai išbandyti kiekvieną sistemos paslaugos funkcionalumą nemokamai. Nemokamam išbandymui Jums reikia pasirinkti  paslaugą ir aktyvuoti nuorodą "IŠBANDYTI NEMOKAMAI 10 DIENŲ".'
                      )}
                      <br />
                      <br />
                      {t(
                        'Pasibaigus šiam terminui, paslaugos aktyvios būsenos pratęsimui,  formuokite paslaugų užsąkymą, nurodydami paslaugos trukmę ir darbo vietų skaičių.'
                      )}
                      <br />
                      <br />
                      {t(
                        'Užsakytos paslaugos bus aktyvuotos po sąskaitos, kurią gausite į užsąkyme nurodytą el. paštą, apmokėjimo.'
                      )}
                      <br />
                      <br />
                    </>
                  ) : (
                    <>
                      <br />
                      {t(
                        'Elektroninė informacinė sistema edu.bimgates.lt yra skirta mokymo įstaigų dėstytojams ir studentams, kaip papildoma priemonė mokymo procese. Sistema  edu.bimgates.lt yra riboto naudojimo ir  gali būti naudojama išskirtinai mokymo tikslams.'
                      )}
                      <br />
                      {t(
                        'Kiekvienoje mokymo įstaigoje registruojamos atskiros mokymo įstaigos padalinio paskyros, o vartotojų (studentų) prisijungimus prie atitinkamų paskyrų, aktyvuoja atsakingas dėstytojas.'
                      )}
                      <br />
                      <br />
                      {t(
                        'Komerciniam naudojimui privaloma suformuoti paslaugų užsąkymą, užsiregistravus sistemoje'
                      )}{' '}
                      <a href="https://bimgates.lt" target="_blank">
                        bimgates.lt
                      </a>
                      .
                      <br />
                      <br />
                    </>
                  )}
                </Typography>
                <br />
                <hr />

                <Typography variant="body2" style={{ marginTop: 10 }}>
                  {t(
                    'Visos e. duomenų bazės teisės priklauso UAB Sistela. Duomenų bazė negali būti kopijuojama ar perdaroma į bet kokią kitą formą ir naudojama komerciniais ar kitais tikslais be autorių sutikimo.'
                  )}
                  <br />
                  {t(
                    'Jei nenorite, kad Jūsų registracija būtų išsaugota sistemoje, prašome parašyti prašymą į'
                  )}{' '}
                  <a href="mailto:info@bimgates.lt">info@bimgates.lt</a>
                  <br />
                  <br />
                  {t('Pagarbiai')},
                  <br />
                  {t('bimgates.lt administratorius')}
                </Typography>
              </CommonPanel>
            </Grid>

            <Grid item xs={6}>
              <CommonPanel>
                <Typography variant="h6">{t('Naujienos')}</Typography>

                <div className={classes.newsWrap}>
                  <NewsWidget />
                </div>
              </CommonPanel>
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation()
)(Dashboard);
