import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

// Router support
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Badge } from '@material-ui/core';

// Material components
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  IconButton
  // Typography,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
  FolderOutlined as FolderIcon,
  LabelOutlined as LabelIcon,
  Label as LabelDarkIcon,
  WorkOutline as WorkIcon
} from '@material-ui/icons';

import { canAccess, getCurrentAccount } from 'utils/helpers';

// Component styles
import styles from './styles';

class Sidebar extends Component {
  handleListItemActive(url) {
    const { classes, location } = this.props;
    return location.pathname === url
      ? classes.activeListItem
      : classes.listItem;
  }

  renderListItem(
    { layoutName, to, label, labelId, icon, isPublic = false, badgeText },
    disableUnavailable = false
  ) {
    const { classes } = this.props;
    const { isDefault } = getCurrentAccount();
    const allowed = canAccess(layoutName);

    // Hide layouts that are not accessible
    if (!disableUnavailable && !isPublic && !allowed) {
      return null;
    }

    if (!disableUnavailable && !allowed) {
      return null;
    }

    const item = (
      <ListItem
        className={this.handleListItemActive(to)}
        component={!isDefault ? Link : undefined}
        to={to + (allowed ? '' : '?trial=1')}
        key={`${layoutName}-${labelId}`}
        disabled={isDefault}>
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary={label}
        />
      </ListItem>
    );

    return badgeText ? (
      <Badge key={`${layoutName}-${labelId}-badge`} component="div" badgeContent={badgeText} color="secondary" overlap="rectangular">
        {item}
      </Badge>
    ) : (
      item
    );
  }

  renderCommonMenu() {
    const { t } = this.props;
    const items = [
      // {
      //   layoutName: 'dashboard.layout',
      //   isPublic: true,
      //   to: '/dashboard',
      //   label: 'Apžvalga',
      //   labelId: 'Dashboard',
      //   icon: <FolderIcon />,
      // },
      {
        layoutName: 'projects.layout',
        to: '/projects',
        label: t('Projektai'),
        labelId: 'Projects',
        icon: <FolderIcon />
      },
      {
        layoutName: 'documents.layout',
        to: '/documents',
        label: t('Dokumentai'),
        labelId: 'Documents',
        icon: <FolderIcon />
      }
    ];

    // const { classes } = this.props;
    const listItems = _.compact(
      _.map(items, item => this.renderListItem(item))
    );

    if (!listItems.length) {
      return null;
    }

    return (
      <div>
        {/* <Divider className={classes.listDivider} /> */}
        <List component="div" disablePadding>
          {listItems}
        </List>
      </div>
    );
  }

  renderSpecialMenu() {
    const { specialMenu, specialMenuTitle, classes } = this.props;
    const listItems = _.compact(
      _.map(specialMenu, item =>
        this.renderListItem({
          ...item,
          icon: <LabelDarkIcon />
        })
      )
    );

    if (!listItems.length) {
      return null;
    }

    return (
      <div>
        <Divider className={classes.listDivider} />
        <List
          component="div"
          disablePadding
          subheader={
            <ListSubheader className={classes.listSubheader} disableSticky>
              {specialMenuTitle}
            </ListSubheader>
          }>
          {listItems}
        </List>
      </div>
    );
  }

  renderServiceListMenu() {
    const { t } = this.props;
    const services = [
      {
        layoutName: 'estimates.layout,permissions.layout',
        to: '/projects?estimates',
        label: t('Sąmatų skaičiavimai'),
        title: 'Sąmatos (skaičiavimai)',
        labelId: 'Estimates',
        icon: <LabelIcon />,
        // badgeText: t('Naujiena')
      },
      {
        layoutName: 'recovery-price.layout,permissions.layout',
        to: '/recovery-price',
        label: t('Atkuriamosios vertės skaičiavimai'),
        title: 'Atkuriamoji vertė (skaičiavimai)',
        labelId: 'RecoveryPrice',
        icon: <LabelIcon />
      },
      {
        layoutName: 'norms.layout-calc,permissions.layout',
        to: '/norms-calc',
        label: t('Statybos darbų kainos skaičiavimai'),
        title: 'Statybos darbai (normatyvai ir kainos skaičiavimai)',
        labelId: 'NormsCalc',
        icon: <LabelIcon />
      }
    ];

    const viewers = [
      {
        layoutName: 'ntk.layout,permissions.layout',
        to: '/ntk',
        label: t('Statinių kainynas (NTK)'),
        labelId: 'NtkViewer',
        icon: <LabelIcon />
      },
      {
        layoutName: 'services.ntk.pricelist-investments,permissions.layout',
        to: '/investments',
        label: t('Statinių palyginamieji rodikliai'),
        labelId: 'InvestmentViewer',
        icon: <LabelIcon />
      },
      {
        layoutName: 'norms.layout-pbw,permissions.layout',
        to: '/norms/pbw', // PBW - prices of bulk work
        label: t('Sustambintų darbų kainynas'),
        labelId: 'NormsSD',
        icon: <LabelIcon />
      },
      {
        layoutName: 'norms.layout-prw,permissions.layout',
        to: '/norms/prw', // PRW - Price list of renovation works
        label: t('Renovacijos darbų kainynas'),
        labelId: 'NormsRD',
        icon: <LabelIcon />
      }
    ];

    const { classes } = this.props;
    const serviceList = _.compact(
      _.map(services, item => this.renderListItem(item, true))
    );
    const viewerList = _.compact(
      _.map(viewers, item => this.renderListItem(item, true))
    );

    if (!serviceList.length && !viewerList.length) {
      return null;
    }

    return (
      <div>
        {serviceList.length ? (
          <>
            <Divider className={classes.listDivider} />
            <List
              component="div"
              disablePadding
              subheader={
                <ListSubheader className={classes.listSubheader} disableSticky>
                  {t('Paslaugos')}
                </ListSubheader>
              }>
              {serviceList}
            </List>
          </>
        ) : null}

        {viewerList.length ? (
          <>
            <Divider className={classes.listDivider} />
            <List
              component="div"
              disablePadding
              subheader={
                <ListSubheader className={classes.listSubheader} disableSticky>
                  {t('Žinių bazė')}
                </ListSubheader>
              }>
              {viewerList}
            </List>
          </>
        ) : null}
      </div>
    );
  }

  renderAdminMenu() {
    const { t } = this.props;
    const { company } = getCurrentAccount();

    const currentCompanyId = company ? company._id : null;
    const items = [
      {
        layoutName: 'users.layout',
        to: '/users',
        label: t('Vartotojai'),
        labelId: 'Users',
        icon: <WorkIcon />
      },
      {
        layoutName: 'accounts.layout',
        to: '/accounts',
        label: t('Paskyros'),
        labelId: 'Accounts',
        icon: <WorkIcon />
      },
      {
        layoutName: 'account-licence-logs.layout',
        to: '/account-licence-logs',
        label: t('Paskyrų licencijų žurnalas'),
        labelId: 'AccountLicenceLogs',
        icon: <WorkIcon />
      },
      {
        layoutName: 'companies.services',
        to: '/companies/services',
        label: t('Paslaugų valdymas'),
        labelId: 'ServicesManager',
        icon: <WorkIcon />
      },
      {
        layoutName: 'companies.orders',
        to: '/orders',
        label: t('Paslaugų užsakymai'),
        labelId: 'OrdersList',
        icon: <WorkIcon />
      },
      {
        layoutName: 'templates.layout',
        to: '/discount-codes',
        label: t('Nuolaidų kodai'),
        labelId: 'DiscountCodesList',
        icon: <WorkIcon />
      },
      {
        layoutName: 'companies.write',
        to: `/companies/${currentCompanyId}`,
        label: t('Įmonės duomenys'),
        labelId: 'CompaniesForm',
        icon: <WorkIcon />
      },
      {
        layoutName: 'companies.layout',
        to: '/companies',
        label: t('Įmonės'),
        labelId: 'Companies',
        icon: <WorkIcon />
      },
      {
        layoutName: 'permissions.layout',
        to: '/permissions',
        label: t('Prieigos teisės'),
        labelId: 'Permissions',
        icon: <WorkIcon />
      },
      {
        layoutName: 'templates.layout',
        to: '/tree-templates',
        label: t('Struktūros šablonai'),
        labelId: 'TreeTemplates',
        icon: <WorkIcon />
      },
      {
        layoutName: 'templates.layout',
        to: '/templates',
        label: t('Elementų šablonai'),
        labelId: 'Template',
        icon: <WorkIcon />
      },
      {
        layoutName: 'classifier.layout',
        to: '/classifier',
        label: t('Klasifikatorius'),
        labelId: 'Classifier',
        icon: <WorkIcon />
      },
      {
        layoutName: 'classifier-groups.layout',
        to: '/classifier-groups',
        label: t('Klasifikatoriaus grupės'),
        labelId: 'ClassifierGroups',
        icon: <WorkIcon />
      },
      {
        layoutName: 'norm-packages.layout',
        to: '/norm-packages',
        label: t('Normatyvų paketai'),
        labelId: 'NormPackages',
        icon: <WorkIcon />
      },
      {
        layoutName: 'services.layout',
        to: '/services',
        label: t('Paslaugos'),
        labelId: 'Services',
        icon: <WorkIcon />
      },
      {
        layoutName: 'service-packages.layout',
        to: '/service-packages',
        label: t('Paslaugų paketai'),
        labelId: 'ServicePackages',
        icon: <WorkIcon />
      },
      {
        layoutName: 'news.layout',
        to: '/news',
        label: t('Naujienos'),
        labelId: 'News',
        icon: <WorkIcon />
      }
    ];

    // if (!canAccess('administrator.layout')) {
    //   return;
    // }

    const listItems = _.compact(
      _.map(items, item => this.renderListItem(item))
    );

    if (!listItems.length) {
      return null;
    }

    const { classes } = this.props;
    return (
      <div>
        <Divider className={classes.listDivider} />
        <List
          component="div"
          disablePadding
          subheader={
            <ListSubheader className={classes.listSubheader} disableSticky>
              {t('Administravimas')}
            </ListSubheader>
          }
          style={{ marginBottom: '30px' }}>
          {listItems}
        </List>
      </div>
    );
  }

  render() {
    const { classes, className, specialMenu, onToggleSidebar } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <Scrollbars autoHide>
        <nav className={rootClassName}>
          <div className={classes.logoWrapper}>
            <IconButton
              className={classes.menuButton}
              onClick={onToggleSidebar}
              variant="text">
              <CloseIcon />
            </IconButton>

            <Link className={classes.logoLink} to="/">
              <img
                alt="bimgates"
                className={classes.logoImage}
                src="/images/logos/logo.svg"
              />
            </Link>
          </div>

          {this.renderCommonMenu()}

          {specialMenu ? this.renderSpecialMenu() : null}

          {this.renderServiceListMenu()}

          {this.renderAdminMenu()}
        </nav>
      </Scrollbars>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return { ...app };
};

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  specialMenu: PropTypes.array,
  specialMenuTitle: PropTypes.string
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation()
)(Sidebar);
